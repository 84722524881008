//const API_URL = "http://localhost:3000";
//const API_URL = "http://192.168.26.70:3000";

//const API_URL = "http://192.168.137.1:3000";

//const API_URL = "http://192.168.0.103:3000";

//const API_URL = "http://192.168.59.70:3000";


const API_URL = "https://api.pokladna.jankarlik.cz";

export { API_URL };