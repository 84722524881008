<template>
    <header>
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container-fluid">
                <a class="navbar-brand fs-4">{{ name }} - {{ type_name }}</a>
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <button @click="reloadPage" class="btn btn-reload rounded-circle">
                    <i class="fa fa-refresh"></i>
                </button>
                <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                    <ul class="navbar-nav text-center align-items-center" v-if="isUserType(1) || isUserType(2)">
                        <li class="nav-item">
                            <router-link
                                :class="{ 'nav-link': true, 'active': $route.path === '/home', 'disabled': !authenticated }"
                                to="/home">Pokladna</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :class="{ 'nav-link': true, 'active': $route.path === '/dispense' }"
                                to="/dispense">Výdej</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :class="{ 'nav-link': true, 'active': $route.path === '/stock' }"
                                to="/stock">Sklad</router-link>
                        </li>
                        <li v-if="isUserType(2)" class="nav-item">
                            <router-link :class="{ 'nav-link': true, 'active': $route.path === '/customers' }"
                                to="/customers">Zákazníci</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :class="{ 'nav-link': true, 'active': $route.path === '/orders' }"
                                to="/orders">Objednávky <br> pokladna</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :class="{ 'nav-link': true, 'active': $route.path === '/orders/storage' }"
                                to="/orders/storage">Objednávky <br> Sklad</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :class="{ 'nav-link': true, 'active': $route.path === '/settings' }"
                                to="/settings">Nastavení</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :class="{ 'btn btn-info': true, 'active': $route.path === '/about' }"
                                to="/about"><i class="fa-regular fa-circle-question"></i></router-link>
                        </li>
                        <li @click="logout" class="nav-item">
                            <button type="button" class="btn btn-danger"><i class="fa-solid fa-right-from-bracket"></i></button>
                        </li>
                        <li @click="fullscreen" class="nav-item">
                            <button type="button" class="btn btn-warning"><i class="fa-solid fa-expand"></i></button>
                        </li>
                    </ul>
                    <ul class="navbar-nav" v-else-if="isUserType(4)">
                        <li class="nav-item">
                            <router-link :class="{ 'btn': true, 'active': $route.path === '/administration/accounts' }"
                                to="/administration/accounts">Účty</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                :class="{ 'btn': true, 'active': $route.path.includes('/administration/checkout') }"
                                to="/administration/checkout/categories">Pokladna</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                :class="{ 'btn': true, 'active': $route.path.includes('/administration/storage') }"
                                to="/administration/storage/categoriesProducts">Sklad</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                :class="{ 'btn': true, 'active': $route.path.includes('/customers') }"
                                to="/customers">Zákazníci</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                :class="{ 'btn': true, 'active': $route.path.includes('/administration/organizers') }"
                                to="/administration/organizers">Pořadatelé</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :class="{ 'btn btn-info': true, 'active': $route.path === '/about' }"
                                to="/about"><i class="fa-regular fa-circle-question"></i></router-link>
                        </li>
                        <li @click="logout" class="nav-item">
                            <button type="button" class="btn btn-danger"><i class="fa-solid fa-right-from-bracket"></i></button>
                        </li>
                        <li @click="fullscreen" class="nav-item">
                            <button type="button" class="btn btn-warning"><i class="fa-solid fa-expand"></i></button>
                        </li>
                    </ul>
                    <ul class="navbar-nav" v-else-if="isUserType(3)">
                        <li class="nav-item">
                            <router-link :class="{ 'nav-link': true, 'active': $route.path === '/storage/dashboard' }"
                                to="/storage/dashboard">Dashboard</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :class="{ 'nav-link': true, 'active': $route.path === '/storage/management' }"
                                to="/storage/management">Správa skladu</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :class="{ 'nav-link': true, 'active': $route.path === '/storage/orders' }"
                                to="/storage/orders">Objednávky</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :class="{ 'btn btn-info': true, 'active': $route.path === '/about' }"
                                to="/about"><i class="fa-regular fa-circle-question"></i></router-link>
                        </li>
                        <li @click="logout" class="nav-item">
                            <button type="button" class="btn btn-danger"><i class="fa-solid fa-right-from-bracket"></i></button>
                        </li>
                        <li @click="fullscreen" class="nav-item">
                            <button type="button" class="btn btn-warning"><i class="fa-solid fa-expand"></i></button>
                        </li>
                    </ul>
                    <ul v-else class="navbar-nav">
                        <li class="nav-item">
                            <router-link :class="{ 'btn btn-success': true, 'active': $route.path === '/' }"
                                to="/">Login</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :class="{ 'btn btn-info': true, 'active': $route.path === '/monitor' }"
                                to="/monitor">Monitor</router-link>
                        </li>
                        <li @click="fullscreen" class="nav-item">
                            <button type="button" class="btn btn-warning"><i class="fa-solid fa-expand"></i></button>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    </header>
</template>

<script>
import authService from "@/services/authService";

export default {
    data() {
        return {
            authenticated: false,
            user_type: null,
            name: localStorage.getItem("name") || "FF Pokladna",
            id_type: localStorage.getItem("id_user_type") || null,
            type_name: localStorage.getItem("user_type_name") || "Login"
        }
    },
    async created() {
        let pom;
        try {
            pom = await authService.isAuthenticated();
            this.authenticated = true;
        } catch (error) {
            this.authenticated = false;
        }
    },
    watch: {
        // Sledování změn v $route objektu
        '$route': async function (newRoute, oldRoute) {
            // Získání aktuální cesty URL
            const currentPath = newRoute.path;

            let pom;
            try {
                pom = await authService.isAuthenticated();
                this.authenticated = true;
            } catch (error) {
                this.authenticated = false;
            }

            if (currentPath === '/' && this.authenticated) {
                if (this.id_type == 4) {
                    this.$router.push('/administration/accounts');
                } else if (this.id_type == 3) {
                    this.$router.push('/storage/dashboard');
                } else {
                    this.$router.push('/home');
                }
            }

            this.name = localStorage.getItem("name") || "FF Pokladna";
            this.type_name = localStorage.getItem("user_type_name") || "Login";
            this.id_type = localStorage.getItem("id_user_type") || null;

        }
    },
    methods: {
        logout() {
            authService.logout();
            this.$router.push('/');
        },
        fullscreen() {
            const elem = document.documentElement;
            if (document.fullscreenElement || document.webkitFullscreenElement) {
                localStorage.setItem("fullscreen", false);
                document.exitFullscreen();
            } else {
                localStorage.setItem("fullscreen", true);
                elem.requestFullscreen();
            }
        },
        reloadPage() {
            this.$router.push('/refresh');
        },
        isUserType(type) {
            return this.authenticated && type == this.id_type;
        },
    }
}
</script>

<style scoped>
.nav-item {
    margin: 0 10px;
}

.btn-reload {
    width: 50px;
    /* Vytvoření kruhového tlačítka */
    height: 50px;
    /* Vytvoření kruhového tlačítka */
    padding: 0;
    /* Odebrání paddingu */
    font-size: 24px;
    /* Velikost ikony */
    display: flex;
    /* Flexbox pro centrování ikony */
    align-items: center;
    /* Centrování ikony vertikálně */
    justify-content: center;
    /* Centrování ikony horizontálně */
    border-radius: 50%;
    /* Zaoblení pro kruhový tvar */
    background-color: #17a2b8;
    /* Barva pozadí tlačítka */
    border-color: #17a2b8;
    /* Barva okraje tlačítka */
    color: white;
    /* Barva ikony */
    transition: background-color 0.3s, color 0.3s;
    /* Plynulé přechody pro hover */
}

.btn-reload:hover {
    background-color: #138496;
    /* Tmavší barva při najetí myši */
    color: #d4d4d4;
    /* Světlejší text při najetí myši */
}

.nav-item,
.nav-item button,
.nav-item .btn {
    font-size: 1.2em;
}

</style>