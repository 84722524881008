// store.js
import { createStore } from 'vuex';

export default createStore({
  state: {
    cartItems: [],
    cartItems_storage: []
  },
  mutations: {
    ADD_PRODUCT_TO_CART(state, product) {
      const productIndex = state.cartItems.findIndex(item => item.id_checkout_product === product.id_checkout_product);
      if (productIndex === -1) {
        product = { ...product, quantity: 1, sum_price: product.checkout_product_price };
        state.cartItems.push(product);
      } else {
        state.cartItems[productIndex].quantity++;
        state.cartItems[productIndex].sum_price = state.cartItems[productIndex].quantity * state.cartItems[productIndex].checkout_product_price;
      }
    },
    CANCEL_ORDER(state) {
      state.cartItems = [];
    },
    ADD_PRODUCT_TO_CART_STORAGE(state, product) {
      const productIndex = state.cartItems_storage.findIndex(item => item.id_storage_product === product.id_storage_product);
      if (productIndex === -1) {
        product = { ...product, quantity: 1 };
        state.cartItems_storage.push(product);
      } else {
        state.cartItems_storage[productIndex].quantity++;
      }
    },
    CANCEL_ORDER_STORAGE(state) {
      state.cartItems_storage = [];
    }
  },
  actions: {
    addProductToCart({ commit }, product) {
      commit('ADD_PRODUCT_TO_CART', product);
    },
    cancelOrder({ commit }) {
      commit('CANCEL_ORDER');
    },
    addProductToCartStorage({ commit }, product) {
      commit('ADD_PRODUCT_TO_CART_STORAGE', product);
    },
    cancelOrderStorage({ commit }) {
      commit('CANCEL_ORDER_STORAGE');
    }
  },
  getters: {
    cartItems: state => state.cartItems,
    cartItems_storage: state => state.cartItems_storage
  }
});
