import { createRouter, createWebHashHistory } from "vue-router";

import authService from "../services/authService"; // Importujte službu pro ověřování přihlášení

const routes = [
  {
    path: "/home",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: { requiresAuth: true, user_types: [1, 2, 3] }, // Přidáno meta pole pro označení stránky jako vyžadující přihlášení
  },
  {
    path: "/refresh",
    name: "refresh",
    component: () => import("../views/RefreshView.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    meta: { requiresAuth: false }, // Přidáno meta pole pro označení stránky jako vyžadující přihlášení
  },
  {
    path: "/stock",
    name: "stock",
    component: () => import("../views/StockView.vue"),
    meta: { requiresAuth: true, user_types: [1, 2, 3] }, // Přidáno meta pole pro označení stránky jako vyžadující přihlášení
  },
  {
    path: "/customers",
    name: "customers",
    component: () => import("../views/CustomersView.vue"),
    meta: { requiresAuth: true, user_types: [2, 4] }, // Přidáno meta pole pro označení stránky jako vyžadující přihlášení
  },
  {
    path: "/customer/:id",
    name: "customer",
    component: () => import("../views/CustomerView.vue"),
    meta: { requiresAuth: true, user_types: [2, 4] },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/SettingsView.vue"),
    meta: { requiresAuth: true, user_types: [1, 2, 3, 4] }, // Přidáno meta pole pro označení stránky jako vyžadující přihlášení
  },
  {
    path: "/",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/monitor",
    name: "monitorAll",
    component: () => import("../views/MonitorView.vue"),
  },
  {
    path: "/monitor/:id",
    name: "monitorSpecific",
    component: () => import("../views/DispenseMonitorView.vue"),
  },
  {
    path: "/orders",
    name: "orders",
    component: () => import("../views/OrdersView.vue"),
    meta: { requiresAuth: true, user_types: [1, 2] },
  },
  {
    path: "/orders/storage",
    name: "orders/storage",
    component: () => import("../views/OrdersStorageView.vue"),
    meta: { requiresAuth: true, user_types: [1, 2] },
  },
  {
    path: "/order/:id",
    name: "order",
    component: () => import("../views/OrderView.vue"),
    meta: { requiresAuth: true, user_types: [1, 2, 4] },
  },
  {
    path: "/dispense",
    name: "dispense",
    component: () => import("../views/DispenseView.vue"),
    meta: { requiresAuth: true, user_types: [1, 2] },
  },
  {
    path: "/administration/accounts",
    name: "administration-accounts",
    component: () => import("../views/administration/AdministrationAccountsView.vue"),
    meta: { requiresAuth: true, user_types: [4] },
  },
  {
    path: "/administration/checkout/:action",
    name: "administration-checkout",
    component: () =>
      import("../views/administration/AdministrationCheckoutView.vue"),
    meta: { requiresAuth: true, user_types: [4] },
  },
  {
    path: "/administration/storage/:action",
    name: "administration-storage",
    component: () =>
      import("../views/administration/AdministrationStorageView.vue"),
    meta: { requiresAuth: true, user_types: [4] },
  },
  {
    path: "/administration/organizers",
    name: "administration-organizers",
    component: () =>
      import("../views/administration/AdministrationOrganizersView.vue"),
    meta: { requiresAuth: true, user_types: [4] },
  },
  {
    path: "/administration/organizer/:id",
    name: "organizer-detail",
    component: () => import("../views/administration/AdministrationOrganizerView.vue"),
    meta: { requiresAuth: true, user_types: [4] },
  },
  {
    path: "/storage/dashboard",
    name: "storage-dashboard",
    component: () => import("../views/storage/DashboardView.vue"),
    meta: { requiresAuth: true, user_types: [3] },
  },
  {
    path: "/storage/management",
    name: "storage-management",
    component: () => import("../views/storage/ManagementView.vue"),
    meta: { requiresAuth: true, user_types: [3] },
  },
  {
    path: "/storage/orders",
    name: "storage-orders",
    component: () => import("../views/storage/OrdersView.vue"),
    meta: { requiresAuth: true, user_types: [3] },
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // Kontrola, zda trasa vyžaduje autentizaci
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    try {
      // Zkontrolujte, zda je uživatel autentizovaný
      const isAuthenticated = await authService.isAuthenticated();
      if (!isAuthenticated) {
        // Uživatel není autentizovaný, přesměrujte na přihlašovací stránku
        localStorage.removeItem("token");
        localStorage.removeItem("name");
        localStorage.removeItem("id_user_type");
        localStorage.removeItem("user_type_name");
        next({ name: "login" });
        return;
      }

      // Získejte typ uživatele z lokálního úložiště nebo přes API
      const userType = parseInt(localStorage.getItem("id_user_type"));

      // Najděte trasu, která odpovídá přechodu a zjistěte, zda má omezení na typy uživatelů
      const route = to.matched.find(
        (r) => r.meta.requiresAuth && r.meta.user_types
      );
      if (
        route &&
        route.meta.user_types &&
        !route.meta.user_types.includes(userType)
      ) {
        // Typ uživatele není povolen pro tuto trasu, přesměrujte na nepovolenou stránku nebo domovskou stránku
        next({ name: "about" });
        return;
      }

      // Vše je v pořádku, pokračujte na cílovou trasu
      next();
    } catch (error) {
      console.error("Chyba při ověřování autentizace:", error);
      next({ name: "login" });
    }
  } else {
    try {
      const authenticated = await authService.isAuthenticated();
    } catch (error) {
      console.error("Chyba při ověřování autentizace:", error);
      localStorage.removeItem("token");
      localStorage.removeItem("name");
      localStorage.removeItem("id_user_type");
      localStorage.removeItem("user_type_name");
    }
    next();
  }
});

export default router;
