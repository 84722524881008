import axios from "axios";

import { API_URL } from "../config";

export default {
  login(userCredentials) {
    return axios.post(`${API_URL}/auth/login`, userCredentials);
  },
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("name");
    localStorage.removeItem("id_user_type");
    localStorage.removeItem("user_type_name");
    return axios.get(`${API_URL}/auth/logout`);
  },
  isAuthenticated() {
    if (!localStorage.getItem("token")) return Promise.reject();
    return axios.get(`${API_URL}/auth`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
  },
  getUserType() {
    try {
      let pom = axios.get(`${API_URL}/auth/type`, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      return pom.data.id_user_type;
    } catch {
      return Promise.reject();
    }
    
  }
};
