<template>
  <Header />
  <router-view />
</template>

<script>
import Header from './components/Header.vue'

export default {
  components: {
    Header
  }
}
</script>

<style>
/* Custom Scrollbar Styles for WebKit Browsers */
::-webkit-scrollbar {
  width: 32px;
  /* šířka pro vertikální scroll lištu */
  height: 32px;
  /* výška pro horizontální scroll lištu */
}

::-webkit-scrollbar-track {
  background: #2c2c2e;
  /* tmavší pozadí lišty */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #6c757d;
  /* šedá barva pro posuvník */
  border-radius: 10px;
  border: 3px solid #2c2c2e;
  /* tmavé pozadí lišty */
}

::-webkit-scrollbar-thumb:hover {
  background: #495057;
  /* světlejší šedá při hover */
}

/* Custom Scrollbar Styles for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #6c757d #2c2c2e;
}
</style>
